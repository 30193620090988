<template>

    <el-dialog :close-on-click-modal="false" :title="isEdit?'编辑医废编码':'新增医废编码'" :visible.sync="show" width="500px" class="selfInputBox selfInputBoxIV">

        <el-form v-if="formShow" ref="form" :model="value" label-width="80px">

            <el-form-item label="所属医院">
                <el-select filterable v-model="orderInfo.dep_id" placeholder="请选择所属医院" class="SelfSelect">
                    <el-option v-show="depList.length>0" :label="item.name" :value="item.id" v-for="item in depList"
                        :key="item.id"></el-option>
                </el-select>
            </el-form-item>


            <el-form-item label="开始编码" prop="">
                <el-input v-model="orderInfo.code_start" placeholder="请输入开始编码" />
            </el-form-item>


            <el-form-item label="结束编码" prop="">
                <el-input v-model="orderInfo.code_end" placeholder="请输入结束编码" />
            </el-form-item>


        </el-form>


        <span slot="footer">
            <div class="buttons">
                <el-button type="primary" @click="save">保存</el-button>
                <!-- <el-button @click="$refs.form.resetFields()">重置</el-button> -->
            </div>
        </span>

    </el-dialog>
</template>

<script>
    import {
        mapState,
        mapMutations
    } from 'vuex'

    export default {
        model: {
            prop: 'value',
            event: 'change'
        },
        props: {
            value: {
                type: Object,
                required: true
            }
        },
        data() {
            return {
                show: false,
                formShow: false,

                orderInfo: {},
                depList: [],
                isEdit: false
            }
        },

        computed: {
            ...mapState('m_item', ['LevelList', 'PositionList', 'DepList', 'HealthList', 'Province', 'City', 'District',
                'RoleList', 'Depcate', 'ItemType'
            ]),
        },
        watch: {
            show(value) {
                if (this.show) {

                    if (this.depList.length == 0) {
                        this.getdep()
                        setTimeout(() => {
                            this.depList = this.DepList
                        }, 1000)
                    }

                } else {
                    this.isEdit = false
                    this.orderInfo = {}
                }
            },
        },

        methods: {

            open() {
                this.formShow = true
                this.show = true
            },

            refresh() {
                this.show = false
                this.$emit('refresh')
                setTimeout(() => {
                    this.formShow = false
                }, 1000)
            },

            edit(row) {
                // console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", row);

                this.orderInfo = {
                    code_end: row.code_end,
                    code_start: row.code_start,
                    dep_id: row.dep_id,
                    id: row.id,
                }

                this.isEdit = true

                this.open()

            },

            save() {

                if (this.isEdit) {

                    this.$http.put('/api/qrcode/' + this.orderInfo.id, this.orderInfo).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                        if (res.code == 200) {
                            this.$message.success(res.msg);
                            this.refresh()
                        }

                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);
                        }
                    }).catch((err) => {
                        console.log(err)
                    });


                } else {

                    this.$http.post('/api/qrcode', this.orderInfo).then((res) => {
                        console.log("!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!", res);

                        if (res.code == 200) {
                            this.$message.success(res.msg);
                            this.refresh()
                        }

                        if (res.code != 200) { //请求错误
                            this.$message.error(res.msg);
                        }
                    }).catch((err) => {
                        console.log(err)
                    });
                }

            },


        }
    }
</script>

<style>
    /*  .selfInputBoxIV .el-input__inner[placeholder="请选择字典类型"] {
    border: 1px red solid !important;
    box-sizing: content-box !important;
  } */
</style>